import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './CustomizeCard.css';

const CustomizeCard = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCard } = location.state || {};

  if (!selectedCard) {
    navigate('/sendnuqoot');
    return null;
  }

  const handleNext = () => {
    if (!message.trim()) {
      alert('Please enter a message for your card.');
      return;
    }
    navigate('/payment', { state: { selectedCard, message } });
  };

  return (
    <div className="customize-card-container">
      <h1 className="title">Write your personal message</h1>
      
      <div className="card-preview">
        <div className="card-image-container">
          <img
            src={selectedCard.blankImageUrl} // Use the blankImageUrl from the selectedCard
            alt="Blank Card"
            className="card-image12"
          />
          <div className="message-overlay">
            {message}
          </div>
        </div>
      </div>

      <div className="customizedcontainer">
        <textarea
          className="message-input12"
          placeholder="Enter your special message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      
      <button className="next-button" onClick={handleNext}>
        Next
      </button>
    </div>
  );
};

export default CustomizeCard;
