// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benefits-container {
    text-align: center;
    background-color: #946b87; /* Adjust to match your design */
    padding: 60px 20px;
    font-family: 'Poppins';
    color: #ffffff;
  }
  
  .benefits-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .benefits-grid {
    display: flex;
    justify-content: center; /* Center the grid */
    align-items: center;
    gap: 30px;
  }
  
  .benefit-card {
    background-color: transparent; /* Card background color */
    padding: 20px;
    border-radius: 16px;
    width: 280px;
    text-align: center;
  }
  
  .benefit-icon {
    width: 60px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  .benefit-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fef9ed;
  }
  
  .benefit-description {
    font-size: 0.95rem;
    color: #fef9ed;
    line-height: 1.5;
  }
  

  @media (max-width: 700px) {
    .benefits-grid{
      flex-direction: column;
      align-items: center;
      text-align: center;
    }


  }`, "",{"version":3,"sources":["webpack://./src/components/home/BenefitsSection.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,yBAAyB,EAAE,gCAAgC;IAC3D,kBAAkB;IAClB,sBAAsB;IACtB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,oBAAoB;IAC7C,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,6BAA6B,EAAE,0BAA0B;IACzD,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW,EAAE,0BAA0B;IACvC,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,gBAAgB;EAClB;;;EAGA;IACE;MACE,sBAAsB;MACtB,mBAAmB;MACnB,kBAAkB;IACpB;;;EAGF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');\n\n.benefits-container {\n    text-align: center;\n    background-color: #946b87; /* Adjust to match your design */\n    padding: 60px 20px;\n    font-family: 'Poppins';\n    color: #ffffff;\n  }\n  \n  .benefits-title {\n    font-size: 2.5rem;\n    font-weight: bold;\n    margin-bottom: 40px;\n  }\n  \n  .benefits-grid {\n    display: flex;\n    justify-content: center; /* Center the grid */\n    align-items: center;\n    gap: 30px;\n  }\n  \n  .benefit-card {\n    background-color: transparent; /* Card background color */\n    padding: 20px;\n    border-radius: 16px;\n    width: 280px;\n    text-align: center;\n  }\n  \n  .benefit-icon {\n    width: 60px; /* Adjust size as needed */\n    height: auto;\n    margin-bottom: 20px;\n  }\n  \n  .benefit-title {\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #fef9ed;\n  }\n  \n  .benefit-description {\n    font-size: 0.95rem;\n    color: #fef9ed;\n    line-height: 1.5;\n  }\n  \n\n  @media (max-width: 700px) {\n    .benefits-grid{\n      flex-direction: column;\n      align-items: center;\n      text-align: center;\n    }\n\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
