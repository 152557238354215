import { useState } from "react";
import { auth } from "../../firebaseConfig.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./login.css"; // Reuse the CSS from Login

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the navigation hook

  const handleRegister = async () => {
    try {
      // Register the user
      await createUserWithEmailAndPassword(auth, email, password);
      // Navigate to the /sendnuqoot page upon successful registration
      navigate("/sendnuqoot");
    } catch (err) {
      // Handle errors
      switch (err.code) {
        case "auth/email-already-in-use":
          setError("This email is already in use.");
          break;
        case "auth/weak-password":
          setError("Password must be at least 6 characters.");
          break;
        default:
          setError("Registration failed. Please try again.");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        <h2 className="title">Register</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button className="login-button" onClick={handleRegister}>
        Register
      </button>
      <div className="login-links">
        <p>
          Already have an account?{" "}
          <a href="#/login" className="signup-link">
            Log in
          </a>
        </p>
      </div>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default Register;
