import React from 'react';
import './WhyNuqoot.css'; // Import the CSS file

// Example placeholder images (Replace these with actual image URLs or imports)
import moneyImage from '../../images/money-with-bow.png';
import envelopeImage from '../../images/envelope-with-flowers.png';
import watermelonImage from '../../images/watermelon.png';

const WhyNuqoot = () => {
  return (
    <div className="why-nuqoot-container">
      <h2 className="why-nuqoot-title">Why Nuqoot?</h2>
      <div className="why-nuqoot-cards">
        {/* Card 1 */}
        <div className="why-nuqoot-card">
          <img src={moneyImage} alt="Money with a bow" className="card-image"/>
        </div>

        {/* Card 2 */}
        <div className="why-nuqoot-card">
          <img src={envelopeImage} alt="Envelope with flowers" className="card-image"/>
        </div>

        {/* Card 3 */}
        <div className="why-nuqoot-card">
          <img src={watermelonImage} alt="Watermelon slice" className="card-image"/>
        </div>
      </div>
    </div>
  );
};

export default WhyNuqoot;
