import React from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../../firebaseConfig"; // Adjust the path to your firebaseConfig file
import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // Set to true if user exists
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  if (isAuthenticated === null) {
    return <p>Loading...</p>; // Optional: Display a loading state
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
