// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-container {
    text-align: center;
    background-color: #946b87; /* Adjust to match your background */
    padding: 60px 20px;
    font-family: 'Poppins';
    color: #ffffff;
  }
  
  .faq-title {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 40px;
  }
  
  .faq-grid {
    display: flex;
    justify-content: center; /* Center the items */
    align-items: flex-start;
    gap: 40px; /* Space between items */
  }
  
  .faq-item {
    background-color: transparent; /* Card background */
    border-radius: 16px;
    padding: 20px;
    text-align: center;
    width: 300px;
  }
  
  .faq-icon {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  .faq-question {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fef9ed;
  }
  
  .faq-answer {
    font-size: 1.1rem;
    color: #fef9ed;
    line-height: 1.5;
  }


  @media (max-width: 700px) {
    .faq-grid {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/FAQSection.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,yBAAyB,EAAE,oCAAoC;IAC/D,kBAAkB;IAClB,sBAAsB;IACtB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,qBAAqB;IAC9C,uBAAuB;IACvB,SAAS,EAAE,wBAAwB;EACrC;;EAEA;IACE,6BAA6B,EAAE,oBAAoB;IACnD,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW,EAAE,0BAA0B;IACvC,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,gBAAgB;EAClB;;;EAGA;IACE;MACE,sBAAsB;MACtB,mBAAmB;MACnB,kBAAkB;IACpB;EACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');\n\n.faq-container {\n    text-align: center;\n    background-color: #946b87; /* Adjust to match your background */\n    padding: 60px 20px;\n    font-family: 'Poppins';\n    color: #ffffff;\n  }\n  \n  .faq-title {\n    font-size: 1.8rem;\n    font-weight: bold;\n    letter-spacing: 2px;\n    margin-bottom: 40px;\n  }\n  \n  .faq-grid {\n    display: flex;\n    justify-content: center; /* Center the items */\n    align-items: flex-start;\n    gap: 40px; /* Space between items */\n  }\n  \n  .faq-item {\n    background-color: transparent; /* Card background */\n    border-radius: 16px;\n    padding: 20px;\n    text-align: center;\n    width: 300px;\n  }\n  \n  .faq-icon {\n    width: 50px; /* Adjust size as needed */\n    height: auto;\n    margin-bottom: 20px;\n  }\n  \n  .faq-question {\n    font-size: 1.4rem;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #fef9ed;\n  }\n  \n  .faq-answer {\n    font-size: 1.1rem;\n    color: #fef9ed;\n    line-height: 1.5;\n  }\n\n\n  @media (max-width: 700px) {\n    .faq-grid {\n      flex-direction: column;\n      align-items: center;\n      text-align: center;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
