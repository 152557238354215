import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Replace with your Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIWqRuZSBVq7Czk-jHTzUGNLAyMQTJuNo",
    authDomain: "send-nuqoot.firebaseapp.com",
    projectId: "send-nuqoot",
    storageBucket: "send-nuqoot.appspot.com", // Corrected storage bucket URL
    messagingSenderId: "181868205622",
    appId: "1:181868205622:web:784c0841865d565f286a97",
    measurementId: "G-BLGRFHRMWV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);
