import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../src/firebaseConfig';
import { useParams } from 'react-router-dom';
import './AcceptMoney.css'; // Import the CSS file

const AcceptMoney = () => {
  const { id: giftId } = useParams(); // Retrieve giftId from route
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError('Stripe has not fully loaded.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Create a payment token
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    try {
      // Call server to create bank account and payout
      const response = await fetch('http://localhost:8080/payout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token.id, giftId }),
      });

      const result = await response.json();

      if (response.ok) {
        // Mark gift as accepted in Firestore
        const giftRef = doc(db, 'gifts', giftId);
        await updateDoc(giftRef, { status: 'accepted', acceptedAt: new Date() });

        setSuccess(true);
      } else {
        setError(result.error || 'Failed to process the payout.');
      }
    } catch (serverError) {
      setError('Server error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="accept-money-container">
      <h1 className="accept-money-title">Accept Your Gift</h1>
      {!success ? (
        <form onSubmit={handleSubmit} className="accept-money-form">
          <div className="card-element-container">
            <CardElement
              options={{
                style: {
                  base: {
                    color: '#32325d',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                  },
                },
              }}
            />
          </div>
          <button
            type="submit"
            disabled={!stripe || loading}
            className="accept-money-button"
          >
            {loading ? 'Processing...' : 'Accept Funds'}
          </button>
        </form>
      ) : (
        <p className="success-message">
          Your funds are on the way! Thank you for accepting the gift.
        </p>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default AcceptMoney;
