import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../../src/firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import './DeliveryOptions.css';

const DeliveryOptions = () => {
  const [deliveryMethod, setDeliveryMethod] = useState('email');
  const [recipientInfo, setRecipientInfo] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCard, message, amount } = location.state || {};

  if (!selectedCard || !message || !amount) {
    navigate('/sendnuqoot');
    return null;
  }

  const handleSend = async () => {
    try {
      const giftId = uuidv4();
      const giftData = {
        selectedCard,
        message,
        amount,
        senderId: auth.currentUser?.uid,
        recipientInfo,
        deliveryMethod,
        status: 'pending',
        createdAt: new Date(),
      };

      await setDoc(doc(db, 'gifts', giftId), giftData);

      const link = `${window.location.origin}/#/gift/${giftId}`;
      await sendLinkToRecipient(recipientInfo, link, deliveryMethod);
      alert('Gift sent successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error sending gift:', error);
      alert('Failed to send the gift. Please try again.');
    }
  };

  const sendLinkToRecipient = async (recipientInfo, link, deliveryMethod) => {
    // Use production endpoint
    const apiBaseUrl = 'https://sendnuqoot.com'; // your domain

    if (deliveryMethod === 'email') {
      const response = await fetch(`${apiBaseUrl}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ recipientEmail: recipientInfo, link }),
      });

      if (!response.ok) {
        console.error('Failed to send email');
        alert('Failed to send email. Please try again.');
      }
    } else if (deliveryMethod === 'sms') {
      const response = await fetch(`${apiBaseUrl}/send-sms`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ recipientPhone: recipientInfo, link }),
      });

      if (!response.ok) {
        console.error('Failed to send SMS');
        alert('Failed to send SMS. Please try again.');
      }
    }
  };

  return (
    <div className="delivery-options-container">
      <h1 className="title">Choose Delivery Method</h1>
      <select
        className="delivery-select"
        value={deliveryMethod}
        onChange={(e) => setDeliveryMethod(e.target.value)}
      >
        <option value="email">Send via Email</option>
        <option value="sms">Send via SMS</option>
      </select>
      {deliveryMethod && (
        <input
          className="recipient-input"
          type="text"
          placeholder={
            deliveryMethod === 'email' ? 'Recipient Email' : 'Recipient Phone Number'
          }
          value={recipientInfo}
          onChange={(e) => setRecipientInfo(e.target.value)}
        />
      )}
      <button className="send-button" onClick={handleSend}>
        Send
      </button>
    </div>
  );
};

export default DeliveryOptions;
