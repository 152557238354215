import React from 'react';
import { useLocation } from 'react-router-dom';
import './ShareLink.css'; // Import the CSS file

const ShareLink = () => {
  const location = useLocation();
  const { link } = location.state || {};

  if (!link) {
    return <p className="error-text">Error: No link available.</p>;
  }

  return (
    <div className="sharelink-container">
      <h1 className="sharelink-title">Your Shareable Link</h1>
      <div className="link-container">
        <input
          type="text"
          value={link}
          readOnly
          className="link-input"
        />
        <button
          className="copy-button"
          onClick={() => navigator.clipboard.writeText(link)}
        >
          Copy Link
        </button>
      </div>
    </div>
  );
};

export default ShareLink;
