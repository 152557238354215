// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-containe{
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  .home-page {
    position: relative;
  }
  
  .dashboard-container {
    position: relative;
    z-index: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
    height:auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,kDAAkD;IAClD,WAAW;EACb","sourcesContent":[".home-page-containe{\n    margin: 0 auto;\n    padding: 0 20px;\n    box-sizing: border-box;\n    overflow-x: hidden;\n  }\n  \n  .home-page {\n    position: relative;\n  }\n  \n  .dashboard-container {\n    position: relative;\n    z-index: 1;\n    transition: transform 0.5s ease, opacity 0.5s ease;\n    height:auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
