import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './Payment.css'; 

const stripePromise = loadStripe('pk_test_51QGgyuCE67yle5vzH51xhRRDPjwVak7egOgwzxabIMTx5gHpb9dEqF6sVHEdMqu79D2Ulcfapmdb6phKsy11NPuc00jfyWSeTr');

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [flipped, setFlipped] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCard, message } = location.state || {};

  const stripe = useStripe();
  const elements = useElements();

  if (!selectedCard || !message) {
    navigate('/sendnuqoot');
    return null;
  }

  const handlePayment = async () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }

    // For demonstration, assume payment is successful
    navigate('/delivery-options', { state: { selectedCard, message, amount } });
  };

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  const isMobile = window.innerWidth < 600;
  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontSize: isMobile ? '12px' : '16px',
        '::placeholder': {
          color: '#aab7c4',
          height: isMobile ? '20px' : '30px',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div className="payment-container">
      <h1 className="title">Attach a Cash Gift to Your Card</h1>
      <p className="flip-instruction">Click the card to flip and view the personalized message</p>

      <div className="card-flip-container" onClick={handleCardClick}>
        <div className={`card-flip ${flipped ? 'flipped' : ''}`}>
          {/* Front Side (Original Card) */}
          <div className="card-front">
            <div className="card-size-wrapper">
              <img src={selectedCard.imageUrl} alt={selectedCard.name || 'Card'} className="card-image1234" />
              {selectedCard.name && <p className="card-name">{selectedCard.name}</p>}
            </div>
          </div>

          {/* Back Side (Message Overlay Card) */}
          <div className="card-back">
            <img src={selectedCard.blankImageUrl} alt="Blank Card" className="card-image1234" />
            <div className="message-overlay">
              {message}
            </div>
          </div>
        </div>
      </div>
      
      <div className="amount-input-container">
        <span className="amount-currency">$</span>
        <input
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="amount-input"
        />
      </div>

      <div className="card-element-container">
        <CardElement options={cardStyle} />
      </div>
      <button onClick={handlePayment} className="pay-button">
        Pay and Continue
      </button>
    </div>
  );
};

const Payment = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default Payment;
