// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    font-family: 'Poppins';
    justify-content: center;
  }
  
  .card-item {
    width: 150px;
    height: 200px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .card-item:hover {
    transform: scale(1.05);
  }
  
  .card-item.selected {
    border: 2px solid #4CAF50; /* Highlight color */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CardSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB,EAAE,oBAAoB;EACjD","sourcesContent":[".card-selector-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n    font-family: 'Poppins';\n    justify-content: center;\n  }\n  \n  .card-item {\n    width: 150px;\n    height: 200px;\n    border: 2px solid transparent;\n    cursor: pointer;\n    transition: transform 0.2s;\n  }\n  \n  .card-item:hover {\n    transform: scale(1.05);\n  }\n  \n  .card-item.selected {\n    border: 2px solid #4CAF50; /* Highlight color */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
