import React from 'react';
import './AboutSection.css';
import OurStory from '../../images/OurStory.png'; // Update with the actual name of your image file

const ourStory = () => {
  return (
    <div className="about-section">
      <div className="about-content">
        <div className="text-section">
          <h1>
            Our <span className="highlight">Story</span>
          </h1>
          <p>
          Nuqoot's founder was bustling around as a bridesmaid, caught up in the magic of her best friend’s big day. In the midst of all the excitement, she realized she had forgotten to grab a card and cash. A few days later, she wanted to send something meaningful—but the idea of using a plain Venmo or Zelle felt, well... plain. That’s when the idea for Nuqoot was born: a thoughtful way to send money that carries heart and sentiment.
          </p>
        </div>
        <div className="image-section">
          <img src={OurStory} alt="Decorative leaf" className="about-image" />
        </div>
      </div>
    </div>
  );
};

export default ourStory;
