import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Footer.css';
import logo from './images/logo1.png';
import moccet from './images/moccet.png';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (path) => {
    navigate(path);
  };


  const handleAboutClick = () => {
    if (location.pathname === '/') {
      const aboutEl = document.getElementById('about-section');
      if (aboutEl) {
        aboutEl.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/', { state: { scrollTo: 'about' } });
    }
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Left Section (Logo/Brand) */}
        <div className="footer-left">
        <img src={logo} alt="Logo" className="logo-image" />
        </div>

        {/* Middle Section (Links) */}
        <div className="footer-middle">
          <ul className="footer-links">
            <p onClick={() => handleClick('/')}>Home</p>
            <p onClick={() => handleClick('/sendnuqoot')}>Send a gift</p>
            <p onClick={handleAboutClick}>About</p>
            <p onClick={() => handleClick('/sendnuqoot')}>Cards</p>
          </ul>
        </div>

        {/* Right Section (Social) */}
        <div className="footer-right">
          <p className="footer-connect">Let's connect!</p>
          <div className="social-icons">
            {/* Replace i tags with <img> or FontAwesome icons as needed */}
            <i className="fab fa-instagram" aria-hidden="true"></i>
            <i className="fab fa-twitter" aria-hidden="true"></i>
            <i className="fab fa-linkedin-in" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
      <img src={moccet} alt="Logo" className="logo-image" />
      </div>
    </footer>
  );
};

export default Footer;

      
  