import React from 'react';
import './AboutSection.css';
import aboutImage from '../../images/about.png'; // Update with the actual name of your image file

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="about-content">
      <div className="image-section">
          <img src={aboutImage} alt="Decorative leaf" className="about-image" />
        </div>
        <div className="text-section">
          <h1>
            About <span className="highlight">Nuqoot</span>
          </h1>
          <p>
            Welcome to Nuqoot – where cash gifting is sentimental! Born out of a simple (and slightly frantic!) moment as a bridesmaid, Nuqoot was inspired by the desire to send more than just money – to send love.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
