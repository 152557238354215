import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SendMoneySpecial from '../components/home/SendMoneySpecial';
import AboutSection from '../components/home/AboutSection';
import './Home.css';
import GiftFeatures from '../components/home/GiftFeatures';
import BenefitsSection from '../components/home/BenefitsSection';
import FAQSection from '../components/home/FAQSection';
import ReviewsSection from '../components/home/ReviewsSection';
import Footer from '../../src/Footer';
import WhyNuqoot from '../components/home/WhyNuqoot';
import OurStory from '../components/home/OurStory';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if location state indicates we should scroll to "about"
    if (location.state && location.state.scrollTo === 'about') {
      const aboutEl = document.getElementById('about-section');
      if (aboutEl) {
        aboutEl.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="home-page-container">
      <div className="home-page">
        <div className="dashboard-container" >
          <SendMoneySpecial />
        </div>
        <div className="dashboard-container">
          <GiftFeatures />
        </div>
        {/* Assign the id here */}
        <div className="dashboard-container" id="about-section">
          <AboutSection />
        </div>
        <div className="dashboard-container">
          <WhyNuqoot />
        </div>
        <div className="dashboard-container">
          <OurStory />
        </div>

      </div>
      <Footer/>
    </div>
  );
};

export default Home;
