import React from 'react';
import './BenefitsSection.css';
import reachImage from '../../images/upload.png'; // Replace with actual image name
import engagementImage from '../../images/eye.png'; // Replace with actual image name
import analyticsImage from '../../images/jigsaw.png'; // Replace with actual image name

const BenefitsSection = () => {
  const benefits = [
    {
      image: reachImage,
      title: 'Increased Reach',
      description: 'Write here a key feature of the app or software that is being advertised here.',
    },
    {
      image: engagementImage,
      title: 'Increased Engagement',
      description: 'Write here a key feature of the app or software that is being advertised here.',
    },
    {
      image: analyticsImage,
      title: 'Real Time Analytics',
      description: 'Write here a key feature of the app or software that is being advertised here.',
    },
  ];

  return (
    <div className="benefits-container">
      <h2 className="benefits-title">Benefits</h2>
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <img src={benefit.image} alt={benefit.title} className="benefit-icon" />
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
