// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.send-money-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins';
  padding: 20px;
  background-color: #fef9ed; /* Adjust to match the background color */
  flex-wrap: wrap;
}

.text-section1 {
  max-width: 50%;
  flex: 1 1;
}

.text-section1 h1 {
  font-size: 4rem;
  font-weight: bold;
  color: #333; /* Darker color for contrast */
  margin-bottom: 20px;
}

.text-section1 .highlight {
  color: #6f4c7a; /* Adjust to match your theme */
}

.text-section1 p {
  font-size: 1.4rem;
  color: #555;
}

.image-section1 {
  max-width: 45%; /* Adjust as needed for responsiveness */
  flex: 1 1;
}

.phone-image {
  width: 100%;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .send-money-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-section1,
  .image-section1 {
    max-width: 100%;
  }

  .text-section1 h1 {
    font-size: 2rem;
  }

  .text-section1 p {
    font-size: 1.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/SendMoneySpecial.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,yBAAyB,EAAE,yCAAyC;EACpE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,SAAO;AACT;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW,EAAE,8BAA8B;EAC3C,mBAAmB;AACrB;;AAEA;EACE,cAAc,EAAE,+BAA+B;AACjD;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc,EAAE,wCAAwC;EACxD,SAAO;AACT;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA,sBAAsB;AACtB;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;;IAEE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".send-money-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  max-width: 1200px;\n  margin: 0 auto;\n  font-family: 'Poppins';\n  padding: 20px;\n  background-color: #fef9ed; /* Adjust to match the background color */\n  flex-wrap: wrap;\n}\n\n.text-section1 {\n  max-width: 50%;\n  flex: 1;\n}\n\n.text-section1 h1 {\n  font-size: 4rem;\n  font-weight: bold;\n  color: #333; /* Darker color for contrast */\n  margin-bottom: 20px;\n}\n\n.text-section1 .highlight {\n  color: #6f4c7a; /* Adjust to match your theme */\n}\n\n.text-section1 p {\n  font-size: 1.4rem;\n  color: #555;\n}\n\n.image-section1 {\n  max-width: 45%; /* Adjust as needed for responsiveness */\n  flex: 1;\n}\n\n.phone-image {\n  width: 100%;\n  height: auto;\n}\n\n/* Responsive Styles */\n@media (max-width: 768px) {\n  .send-money-container {\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n\n  .text-section1,\n  .image-section1 {\n    max-width: 100%;\n  }\n\n  .text-section1 h1 {\n    font-size: 2rem;\n  }\n\n  .text-section1 p {\n    font-size: 1.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
