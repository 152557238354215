import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from '../images/logo2.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="bar">
      <div className="navbar">
        {/* Logo */}
        <div className="logo" onClick={() => handleNavigation('/')}>
          <img src={logo} alt="Logo" className="logo-image" />
        </div>

        {/* Center Links (Desktop) */}
        <div className="links-center">
          <div className="links">
            <p onClick={() => handleNavigation('/')}>Home</p>
            <p onClick={() => handleNavigation('/about')}>About</p>
            <div className="dropdown">
              <p className="dropdown-button" onClick={() => handleNavigation('/sendnuqoot')}>Cards</p>
              <ul className="dropdown-menu">
                <li onClick={() => handleNavigation('/sendnuqoot')}>Special Occasions</li>
                <li onClick={() => handleNavigation('/sendnuqoot')}>Holidays</li>
              </ul>
            </div>
            <p onClick={() => handleNavigation('/faq')}>FAQ</p>
          </div>
        </div>

        {/* Right Section (Desktop) */}
        <div className="right-section">
          <button
            className="send-nuqoot-btn"
            onClick={() => handleNavigation('/sendnuqoot')}
          >
            Send Nuqoot
          </button>
        </div>

        {/* Hamburger Menu (Mobile) */}
        <div
          className={`hamburger-icon ${menuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          {menuOpen ? '✖' : '☰'}
        </div>
      </div>

      {/* Mobile Dropdown */}
      {menuOpen && (
        <div className="mobile-dropdown">
          <p onClick={() => handleNavigation('/')}>Home</p>
          <p onClick={() => handleNavigation('/about')}>About</p>
          <div className="dropdown-mobile">
            <p onClick={() => handleNavigation('/sendnuqoot')}>Cards</p>
            <ul>
              <li onClick={() => handleNavigation('/sendnuqoot')}>Special Occasions</li>
              <li onClick={() => handleNavigation('/sendnuqoot')}>Holidays</li>
            </ul>
          </div>
          <button
            className="send-nuqoot-btn-mobile"
            onClick={() => handleNavigation('/sendnuqoot')}
          >
            Send Nuqoot
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
