import React from 'react';
import './SendMoneySpecial.css';
import phoneImage from '../../images/phone.png';

const SendMoneySpecial = () => {
  return (
    <div className="send-money-container">
      <div className="text-section1">
        <h1>
          Send Money, <br />
          <span className="highlight1">Make it Special</span>
        </h1>
        <p>
          Gift any amount, select a card, and send to anyone — even if they’re not on Nuqoot.
        </p>
      </div>
      <div className="image-section1">
        <img src={phoneImage} alt="Nuqoot App on Phone" className="phone-image" />
      </div>
    </div>
  );
};

export default SendMoneySpecial;
