import React from 'react';
import './FAQSection.css';
import image1 from '../../images/image1.png'; // Replace with actual image name
import image2 from '../../images/image2.png'; // Replace with actual image name
import image3 from '../../images/image3.png'; // Replace with actual image name

const FAQSection = () => {
  const faqs = [
    {
      image: image1,
      question: 'What service is best for my business?',
      answer: 'Having an FAQ section is a great way to present information about your product or service. Using the question-and-answer format makes it more relatable to your users.',
    },
    {
      image: image2,
      question: 'Do you offer short-term and long-term solutions?',
      answer: 'Having an FAQ section is a great way to present information about your product or service. Using the question-and-answer format makes it more relatable to your users.',
    },
    {
      image: image3,
      question: 'How long will it take until we see results?',
      answer: 'Having an FAQ section is a great way to present information about your product or service. Using the question-and-answer format makes it more relatable to your users.',
    },
  ];

  return (
    <div className="faq-container">
      <h2 className="faq-title">FAQs</h2>
      <div className="faq-grid">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <img src={faq.image} alt={`FAQ icon ${index + 1}`} className="faq-icon" />
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
