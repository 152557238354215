import React, { useState } from "react";
import "./CardSelector.css"; // Create a CSS file for styling if needed.

const CardSelector = ({ onCardSelect }) => {
  // Array of card image paths
  const cardDesigns = [
    "/cards/card1.png",
    "/cards/card2.png",
    "/cards/card3.png",
    // Add more paths as needed
  ];

  // State to track the selected card
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (card) => {
    setSelectedCard(card);
    // Callback to parent component or save the selection in a database
    if (onCardSelect) {
      onCardSelect(card);
    }
  };

  return (
    <div className="card-selector-container">
      {cardDesigns.map((card, index) => (
        <img
          key={index}
          src={card}
          alt={`Card Design ${index + 1}`}
          className={`card-item ${selectedCard === card ? "selected" : ""}`}
          onClick={() => handleCardClick(card)}
        />
      ))}
    </div>
  );
};

export default CardSelector;
