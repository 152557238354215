import React from 'react';
import './ReviewsSection.css';
import decorativeImage from '../../images/image4.png'; // Replace with actual image name

const ReviewsSection = () => {
  const reviews = [
    {
      name: 'Lillian P.',
      text: "Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.",
    },
    {
      name: 'Thomas L.',
      text: "Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.",
    },
    {
      name: 'Ren K.',
      text: "Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.",
    },
  ];

  return (
    <div className="reviews-container">
      <h2 className="reviews-title">EARLY REVIEWS</h2>
      <div className="reviews-content">
        <img src={decorativeImage} alt="Decorative graphic" className="decorative-image" />
        <div className="reviews-list">
          {reviews.map((review, index) => (
            <div key={index} className="review-item">
              <h3 className="review-name">{review.name}</h3>
              <p className="review-text">{review.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewsSection;
