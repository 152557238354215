import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SendNuqoot.css';
import bbcard1 from '../images/baby1.png';
import bbcard2 from '../images/baby3.png';
import bbcard3 from '../images/baby5.png';
import bbcard4 from '../images/baby7.png';
import card3 from '../images/12.png';
import card4 from '../images/9.png';
import card5 from '../images/1.png';
import card6 from '../images/3.png';
import card7 from '../images/5.png';
import card8 from '../images/7.png';
import card9 from '../images/11.png';
import Footer from '../Footer';

// Add corresponding blank images for each card
import bbcard1_blank from '../images/baby1_blank.png';
import bbcard2_blank from '../images/baby3_blank.png';
import bbcard3_blank from '../images/baby5_blank.png';
import bbcard4_blank from '../images/baby7_blank.png';
import card3_blank from '../images/12_blank.png';
import card4_blank from '../images/9_blank.png';
import card5_blank from '../images/1_blank.png';
import card6_blank from '../images/3_blank.png';
import card7_blank from '../images/5_blank.png';
import card8_blank from '../images/7_blank.png';
import card9_blank from '../images/11_blank.png';

const SendNuqoot = () => {
  const navigate = useNavigate();

  const eventTypes = [
    {
      id: 1,
      name: 'New baby',
      cards: [
        { id: 'bbcard1', imageUrl: bbcard1, blankImageUrl: bbcard1_blank },
        { id: 'bbcard2', imageUrl: bbcard2, blankImageUrl: bbcard2_blank },
        { id: 'bbcard3', imageUrl: bbcard3, blankImageUrl: bbcard3_blank },
        { id: 'bbcard4', imageUrl: bbcard4, blankImageUrl: bbcard4_blank },
      ],
    },
    {
      id: 2,
      name: 'Wedding',
      cards: [
        { id: 'card3', imageUrl: card3, blankImageUrl: card3_blank },
        { id: 'card4', imageUrl: card4, blankImageUrl: card4_blank },
        { id: 'card5', imageUrl: card5, blankImageUrl: card5_blank },
        { id: 'card6', imageUrl: card6, blankImageUrl: card6_blank },
        { id: 'card7', imageUrl: card7, blankImageUrl: card7_blank },
        { id: 'card8', imageUrl: card8, blankImageUrl: card8_blank },
        { id: 'card9', imageUrl: card9, blankImageUrl: card9_blank },
      ],
    },
  ];

  const handleCardSelect = (card) => {
    navigate('/customize-card', { state: { selectedCard: card } });
  };

  return (
    <div className="send-nuqoot-container">
      <h1 className="title">Select a Card</h1>
      {eventTypes.map((event) => (
        <div key={event.id} className="event-section">
          <h2 className="event-title">{event.name}</h2>
          <div className="card-grid">
            {event.cards.map((card) => (
              <div
                key={card.id}
                className="card-item"
                onClick={() => handleCardSelect(card)}
              >
                <img src={card.imageUrl} alt="" className="card-image" />
              </div>
            ))}
          </div>
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default SendNuqoot;
