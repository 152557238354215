import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { auth } from "../../firebaseConfig.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./login.css"; // Import the CSS file

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the navigation hook

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/"); // Navigate to the homepage upon successful login
    } catch (err) {
      setError(err.message); // Handle errors
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        <div className="title">Login</div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button className="login-button" onClick={handleLogin}>
        Continue
      </button>
      <div className="login-links">
        <a href="#/forgot-password" className="forgot-password-link">
          Forgot password?
        </a>
        <p>
          Don’t have an account? <a href="#/signup" className="signup-link">Sign up</a>
        </p>
      </div>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default Login;
