import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; 
import NavBar from './components/navbar';
import Home from './pages/Home';
import Signup from './components/auth/signup';
import Login from './components/auth/signin';
import Cards from './pages/Cards';
import SendNuqoot from './components/SendNuqoot';
import CustomizeCard from './components/CustomizeCard';
import Payment from './components/Payment';
import DeliveryOptions from './components/DeliveryOptions';
import ShareLink from './components/ShareLink';
import RecipientView from './components/RecipientView';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AcceptMoney from './components/AcceptMoney';
import ProtectedRoute from './components/auth/ProtectedRoute'; 
import CardsPreview from './components/CardsPreview';

const stripePromise = loadStripe('pk_test_51QGgyuCE67yle5vzH51xhRRDPjwVak7egOgwzxabIMTx5gHpb9dEqF6sVHEdMqu79D2Ulcfapmdb6phKsy11NPuc00jfyWSeTr');


function App() {
  
  return (
    <Router>
      <NavBar />
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cards" element={<CardsPreview />} />
          <Route path="/sendnuqoot" element={              <ProtectedRoute>
                <SendNuqoot />
              </ProtectedRoute>} />
        <Route path="/customize-card" element={<CustomizeCard />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/delivery-options" element={<DeliveryOptions />} />
        <Route path="/share-link" element={<ShareLink />} />
        <Route path="/gift/:id" element={<RecipientView />} />
        {/* Wrap AcceptMoney with Elements */}
        <Route
          path="/accept-money/:id"
          element={
            <Elements stripe={stripePromise}>
              <AcceptMoney />
            </Elements>
          }
        />
        </Routes>
    </Router>
  );
}

export default App;
