import React, { useState } from "react";
import CardSelector from "../components/CardSelector";

function Cards() {
  const [chosenCard, setChosenCard] = useState(null);

  const handleCardSelection = (card) => {
    console.log("Selected card:", card);
    setChosenCard(card);
    // Optionally, save the card selection to a database
  };

  return (
    <div>
      <h1>Choose a Card Design</h1>
      <CardSelector onCardSelect={handleCardSelection} />
      {chosenCard && <p>You have selected: {chosenCard}</p>}
    </div>
  );
}

export default Cards;
